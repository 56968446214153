<script setup lang="ts">
import IndexServicesBenefit from './IndexServicesBenefit.vue'
import IndexServicesBenefitSkeleton from './IndexServicesBenefitSkeleton.vue'
import Icon from '~/components/global/Icon.vue'
import Container from '~/components/layout/Container.vue'
import { useBox } from '~/composables/api/useBox'

const benefitsResponse = await useAsyncData(() =>
  useBox('index_services_benefits')
)
</script>

<template>
  <div class="c-index-services">
    <Container class="c-index-services__container">
      <div class="c-index-services__image">
        <NuxtImg
          src="/images/pages/index/about-us.jpg"
          :alt="getTextConfiguration('index.services.title')"
          width="620"
          height="420"
          preload
        />

        <div class="sc-bar" />
      </div>

      <div class="c-index-services__main">
        <img
          class="sc-logo"
          src="~/assets/images/CBRE-logo.svg?url"
          alt="CBRE logo"
          loading="lazy"
        />
        <h2 class="sc-title">
          {{ getTextConfiguration('index.services.title') }}
        </h2>
      </div>

      <div class="c-index-services__benefits">
        <template v-if="benefitsResponse.pending.value">
          <IndexServicesBenefitSkeleton v-for="i in 4" :key="i" />
        </template>

        <template v-else>
          <IndexServicesBenefit
            v-for="benefit in benefitsResponse.data.value?.box_items"
            :key="benefit.name"
            :benefit="benefit"
          />
        </template>
      </div>

      <div class="c-index-services__button">
        <IButton
          class="has-icon"
          color="primary"
          :to="
            useLocaleLink({
              name: 'our-services',
            })
          "
        >
          {{ $t('pages.index.services.buttonText') }}

          <Icon name="arrow-right-thick" />
        </IButton>
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-services {
  margin-bottom: 130px;
  overflow: hidden;

  @include breakpoint-down('md') {
    margin-bottom: 120px;
  }

  &__container {
    display: grid;
    grid-template-columns: 620px auto;
    gap: 0 200px;
    align-items: end;

    @include breakpoint-down('xl') {
      grid-template-columns: 520px auto;
      gap: 0 150px;
    }

    @include breakpoint-down('lg') {
      grid-template-columns: 420px auto;
      gap: 0 100px;
    }

    @include breakpoint-down('md') {
      grid-template-columns: 1fr;
    }
  }

  &__image {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .sc-bar {
      position: absolute;
      width: 100%;
      height: 4px;
      z-index: 2;
      bottom: 84px;
      left: 110px;
      display: block;
      background-color: var(--color-secondary);

      @include breakpoint-down('lg') {
        left: 60px;
        bottom: 60px;
      }
    }
  }

  &__main {
    display: flex;
    place-items: flex-start;
    flex-direction: column;
    gap: 110px;

    @include breakpoint-down('xl') {
      gap: 70px;
    }
    @include breakpoint-down('lg') {
      gap: 40px;
    }
    @include breakpoint-down('md') {
      order: -1;
      margin-bottom: 24px;
      gap: 24px;
    }

    .sc-logo {
      width: 120px;
      height: auto;

      @include breakpoint-down('md') {
        width: 90px;
      }
    }

    .sc-title {
      font-size: 58px;
      font-weight: 400;
      line-height: 0.96;
      margin: 0;

      @include breakpoint-down('lg') {
        font-size: 48px;
      }

      @include breakpoint-down('md') {
        font-size: 32px;
      }
    }
  }

  &__benefits {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 64px;
    grid-column: 1 / -1;
    margin: 65px 0 48px;

    @include breakpoint-down('lg') {
      gap: 32px;
    }
    @include breakpoint-down('md') {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint-down('sm') {
      gap: 24px;
    }
  }

  &__button {
    grid-column: 1 / -1;
    display: flex;
    place-content: center;

    :deep(.button) {
      --button--font-size: 18px;
      --button--padding-left: 36px;
      --button--padding-right: 36px;

      min-height: 64px;
      font-weight: 500;

      .ico {
        color: var(--color-secondary);
      }

      @include breakpoint-down('lg') {
        --button--font-size: 16px;
        --button--padding-left: 24px;
        --button--padding-right: 24px;
        min-height: 54px;
      }
    }
  }
}
</style>
