<script setup lang="ts">
import TailoredOfferBanner from '~/components/global/TailoredOfferBanner.vue'
import IndexBestOffers from '~/components/index/IndexBestOffers.vue'
import IndexStatistics from '~/components/index/IndexStatistics.vue'
import IndexServices from '~/components/index/services/IndexServices.vue'
import IndexHero from '~/components/index/hero/IndexHero.vue'
import IndexReferences from '~/components/index/references/IndexReferences.vue'
import IndexArticles from '~/components/index/IndexArticles.vue'
import ClientsLogos from '~/components/global/ClientsLogos.vue'

useFancyHeader()
</script>

<template>
  <div class="c-page-index">
    <IndexHero />
    <IndexServices />
    <IndexStatistics />
    <IndexBestOffers />
    <IndexReferences />
    <IndexArticles />
    <ClientsLogos />
    <TailoredOfferBanner />
  </div>
</template>
