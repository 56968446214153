<script setup lang="ts">
import type { ReferenceDetailResponse } from '~/types/api/ReferenceDetail'

const { t } = useI18n()
const props = defineProps<{
  latestReference: ReferenceDetailResponse
}>()

const highlights = computed(() => {
  const highlights = []

  if (props.latestReference.module_data.premises_size) {
    highlights.push({
      label: t('pages.references.detail.header.premisesSizeLabel'),
      value: props.latestReference.module_data.premises_size,
    })
  }

  if (props.latestReference.module_data.domain) {
    highlights.push({
      label: props.latestReference.module_data.domain_title,
      value: props.latestReference.module_data.domain,
    })
  }

  return highlights
})

const referenceDetailLink = useLocaleLink({
  name: 'references-slug-id',
  params: {
    slug: props.latestReference.slug,
    id: props.latestReference.id,
  },
})
</script>

<template>
  <div class="c-index-references-content">
    <h3 class="c-index-references-content__title">
      {{ latestReference.name }}
    </h3>

    <div class="c-index-references-content__location">
      {{ latestReference.module_data.location }}
    </div>

    <div
      v-if="highlights.length"
      class="c-index-references-content__highlights"
    >
      <div
        v-for="highlight in highlights"
        :key="highlight.label"
        class="c-index-references-content__highlight"
      >
        <span class="sc-label">{{ highlight.label }}</span>
        <div class="sc-value" v-html="highlight.value" />
      </div>
    </div>

    <div class="c-index-references-content__bar" />

    <div
      class="c-index-references-content__description"
      v-html="latestReference.description"
    />

    <div class="c-index-references-content__cta">
      <NuxtLink
        class="c-link c-link--with-leading-bar c-link--block-hover"
        :to="referenceDetailLink"
      >
        <strong>{{ $t('pages.index.references.buttonText') }}</strong>
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-references-content {
  &__title {
    font-size: 32px;
    line-height: 0.92;
    margin-bottom: 16px;

    @include breakpoint-down('md') {
      margin-bottom: 8px;
    }
  }

  &__location {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.32;
    color: var(--color-primary);
    margin-bottom: 24px;

    @include breakpoint-down('xl') {
      font-size: 22px;
      margin-bottom: 16px;
    }

    @include breakpoint-down('lg') {
      font-size: 20px;
      margin-bottom: 14px;
    }
  }

  &__highlights {
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px 64px;

    @include breakpoint-down('xl') {
      margin-bottom: 24px;
      gap: 24px 48px;
    }

    @include breakpoint-down('xs') {
      margin-bottom: 20px;
      gap: 16px 32px;
    }
  }

  &__highlight {
    display: flex;
    flex-direction: column;

    .sc-label {
      font-size: 18px;
      line-height: 1.1;

      @include breakpoint-down('xl') {
        font-size: 16px;
      }

      @include breakpoint-down('xs') {
        font-size: 15px;
      }
    }

    .sc-value {
      font-size: 28px;
      line-height: 1.1;
      font-weight: 600;
      color: var(--color-primary);
      margin-top: 2px;

      @include breakpoint-down('xl') {
        font-size: 22px;
      }

      @include breakpoint-down('lg') {
        font-size: 20px;
      }

      @include breakpoint-down('xs') {
        font-size: 18px;
      }
    }
  }

  &__bar {
    --_bar-overflow: 420px;

    position: relative;
    height: 4px;
    background-color: var(--color-secondary);
    width: calc(100% + var(--_bar-overflow));
    margin-left: calc(-1 * var(--_bar-overflow));
    margin-bottom: 32px;

    @include breakpoint-down('md') {
      --_bar-overflow: 0px;
      height: 2px;
      margin-bottom: 24px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 1.28;
    margin-bottom: 32px;

    & > :deep(*):last-child {
      margin-bottom: 0;
    }

    @include breakpoint-down('md') {
      margin-bottom: 24px;
    }
  }
}
</style>
