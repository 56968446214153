<script setup lang="ts">
import NewsThumbSkeleton from '../news/thumb/NewsThumbSkeleton.vue'
import NewsThumb from '~/components/news/thumb/NewsThumb.vue'
import ItemsSlider from '~/components/global/ItemsSlider.vue'
import Container from '~/components/layout/Container.vue'
import { useAPIRequest } from '~/composables/api/useAPIRequest'
import type { ArticlesResponse } from '~/types/api/Article'

// TODO: needs to rewrite siper
const articlesResponse = await useAsyncData<ArticlesResponse>(async () => {
  return await useAPIRequest('/articles/filter/section_id/1')
})

const slides = computed(
  () => articlesResponse.data.value?.data?.slice(0, 12) || []
)
</script>

<template>
  <div class="c-index-articles">
    <Container class="c-index-articles__container">
      <div class="c-index-articles__label">
        {{ getTextConfiguration('index.articles.label') }}
      </div>

      <h2 class="c-index-articles__title">
        {{ getTextConfiguration('index.articles.title') }}
      </h2>

      <div class="c-index-articles__content">
        <ItemsSlider
          v-if="articlesResponse.pending.value"
          slider-id="news-detail-slider-skeleton"
        >
          <SwiperSlide v-for="i in 2" :key="`skeleton-${i}`">
            <NewsThumbSkeleton />
          </SwiperSlide>
        </ItemsSlider>

        <ItemsSlider v-else-if="slides.length" slider-id="news-detail-slider">
          <SwiperSlide v-for="item in slides" :key="item.id">
            <NewsThumb :article="item" />
          </SwiperSlide>
        </ItemsSlider>
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-articles {
  margin-bottom: 180px;

  @include breakpoint-down('xl') {
    margin-bottom: 130px;
  }

  @include breakpoint-down('md') {
    margin-bottom: 100px;
  }

  &__label {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    color: var(--color-solid-grey-dark);
  }

  &__title {
    margin-bottom: 48px;
    text-align: center;
    line-height: 0.96;

    @include breakpoint-down('md') {
      margin-bottom: 32px;
    }

    @include breakpoint-down('sm') {
      font-size: 32px;
      margin-bottom: 24px;
    }
  }

  &__content {
    :deep(.c-items-slider__arrows) .sc-swiper-button {
      --swiper-button--background-color: var(--color-light);
    }
  }
}
</style>
