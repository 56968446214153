<script setup lang="ts">
import Container from '../layout/Container.vue'
import { useBox } from '~/composables/api/useBox'

const statisticsResponse = await useAsyncData(() =>
  useBox('index_last_year_stats')
)
</script>

<template>
  <div class="c-index-statistics">
    <Container class="c-index-statistics__container">
      <h2 class="c-index-statistics__title">
        {{ getTextConfiguration('index.statistics.title') }}
      </h2>

      <div class="c-index-statistics__main">
        <div class="c-index-statistics__description">
          {{ getTextConfiguration('index.statistics.description') }}
        </div>

        <div class="c-index-statistics__values">
          <template v-if="statisticsResponse.pending.value">
            <div v-for="i in 4" :key="i" class="sc-statistics-skeleton">
              <div class="sc-skeleton"></div>
              <div class="sc-skeleton"></div>
              <div class="sc-skeleton"></div>
            </div>
          </template>

          <template v-else-if="statisticsResponse.data.value">
            <div
              v-for="statistic in statisticsResponse.data.value?.box_items"
              :key="statistic.name"
              class="sc-statistic"
            >
              <strong>{{ statistic.module_data.value }}</strong>
              <span>{{ statistic.name }}</span>
            </div>
          </template>
        </div>
      </div>

      <div class="c-index-statistics__image">
        <NuxtImg
          src="/images/pages/index/satisfied-clients.jpg"
          :alt="getTextConfiguration('index.statistics.title')"
          loading="lazy"
          width="620"
          height="600"
        />

        <div class="sc-bar" />
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;
@use '@/assets/styles/mixins/skeleton' as skeleton;

.c-index-statistics {
  margin-bottom: 110px;
  overflow: hidden;

  @include breakpoint-down('md') {
    margin-bottom: 100px;
  }

  &__container {
    display: grid;
    grid-template:
      'title image' auto
      'main image' auto
      / 1fr 620px;
    gap: 48px;

    @include breakpoint-down('xl') {
      grid-template-columns: 1fr 580px;
    }

    @include breakpoint-down('lg') {
      grid-template-columns: 1fr 500px;
      gap: 24px;
    }

    @include breakpoint-down('md') {
      grid-template:
        'title' auto
        'image' auto
        'main' auto
        / 1fr;
    }
  }

  &__title {
    grid-area: title;
    font-size: 58px;
    line-height: 0.96;
    margin: 0;
    align-self: end;
    max-width: 450px;

    @include breakpoint-down('xs') {
      font-size: 32px;
      max-width: 250px;
    }
  }

  &__main {
    grid-area: main;
  }

  &__description {
    font-size: 18px;
    line-height: 1.28;
    max-width: 460px;
    margin-bottom: 48px;

    @include breakpoint-down('lg') {
      margin-bottom: 24px;
    }
  }

  &__values {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    gap: 40px 58px;

    @include breakpoint-down('lg') {
      gap: 30px 46px;
    }

    @include breakpoint-down('md') {
      grid-template-columns: repeat(4, min-content);
    }

    @include breakpoint-down('sm') {
      grid-template-columns: repeat(2, auto);
      width: 100%;
    }

    .sc-statistics-skeleton {
      display: flex;
      flex-direction: column;

      .sc-skeleton {
        @include skeleton.skeleton;

        &:nth-child(1) {
          width: 122px;
          height: 38px;
          margin-top: 9px;
          margin-bottom: 20px;

          @include breakpoint-down('lg') {
            width: 104px;
            height: 30px;
            margin-bottom: 17px;
          }
          @include breakpoint-down('xs') {
            margin-top: 7px;
            height: 26px;
            margin-bottom: 15px;
          }
        }
        &:nth-child(2) {
          width: 100px;
          height: 17px;
          margin-bottom: 7px;

          @include breakpoint-down('lg') {
            width: 90px;
            height: 16px;
            margin-bottom: 6px;
          }
          @include breakpoint-down('sm') {
            display: none;
          }
          @include breakpoint-down('xs') {
            display: block;
          }
        }
        &:nth-child(3) {
          width: 74px;
          height: 17px;
          margin-bottom: 2px;

          @include breakpoint-down('lg') {
            width: 62px;
            height: 16px;
            margin-bottom: 1px;
          }
        }
      }
    }

    .sc-statistic {
      display: flex;
      flex-direction: column;

      strong {
        font-family: var(--font-family-secondary-base);
        font-weight: 400;
        font-size: 58px;
        line-height: 1;
        color: var(--color-secondary);
        margin-bottom: 4px;
        white-space: nowrap;

        @include breakpoint-down('lg') {
          font-size: 48px;
        }
        @include breakpoint-down('xs') {
          font-size: 40px;
        }
      }

      span {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;

        @include breakpoint-down('lg') {
          font-size: 18px;
        }
        @include breakpoint-down('xs') {
          font-weight: 600;
        }
      }
    }
  }

  &__image {
    grid-area: image;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .sc-bar {
      position: absolute;
      width: 100%;
      height: 4px;
      z-index: 2;
      bottom: 163px;
      right: 100px;
      display: block;
      background-color: var(--color-secondary);

      @include breakpoint-down('lg') {
        bottom: 120px;
      }

      @include breakpoint-down('md') {
        bottom: 68px;
        right: 72px;
      }
    }
  }
}
</style>
