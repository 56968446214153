<script setup lang="ts">
import IndexReferencesContent from './IndexReferencesContent.vue'
import Container from '~/components/layout/Container.vue'
import { useReferencesListing } from '~/composables/api/useReferencesListing'
import { useReferencesDetail } from '~/composables/api/useReferencesDetail'

const referencesResponse = await useAsyncData(async () => {
  return await useReferencesListing()
})

const latestReference = await useAsyncData(async () => {
  const latestReferenceId = referencesResponse?.data?.value?.data?.[0]?.id

  if (!latestReferenceId) {
    return null
  }

  return await useReferencesDetail(latestReferenceId)
})

const imageData = getImageSrcSizesData(latestReference.data?.value?.image, {
  defaultFormat: '640x1280',
  sizes: `
    (max-width: 991.99px) 95vw,
    (max-width: 1199.99px) 500px,
    (max-width: 1399.99px) 540px,
    640px
  `,
  srcsetToGenerate: [
    ['540x1080', '540w'],
    ['1080x2160', '1080w'],

    ['640x1280', '640w'],
    ['1280x2560', '1280w'],
  ],
})
</script>

<template>
  <div v-if="latestReference.data.value" class="c-index-references">
    <Container class="c-index-references__container">
      <div class="c-index-references__label">
        {{ getTextConfiguration('index.references.label') }}
      </div>

      <h2 class="c-index-references__title">
        {{ getTextConfiguration('index.references.title') }}
      </h2>

      <div class="c-index-references__main">
        <div class="c-index-references__image">
          <img
            :src="imageData.src"
            :srcset="imageData.srcset"
            :sizes="imageData.sizes"
            :alt="latestReference.data.value.name || ''"
            loading="lazy"
          />
        </div>

        <div class="c-index-references__content">
          <IndexReferencesContent
            :latest-reference="latestReference.data.value"
          />
        </div>
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-references {
  margin-bottom: 160px;

  @include breakpoint-down('md') {
    margin-bottom: 110px;
  }

  &__label {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    color: var(--color-solid-grey-dark);
  }

  &__title {
    margin-bottom: 90px;
    text-align: center;

    @include breakpoint-down('xl') {
      margin-bottom: 64px;
    }

    @include breakpoint-down('md') {
      margin-bottom: 32px;
    }

    @include breakpoint-down('sm') {
      font-size: 32px;
      margin-bottom: 24px;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: 640px 1fr;
    gap: 140px;
    align-items: center;
    position: relative;

    @include breakpoint-down('xl') {
      grid-template-columns: 540px 1fr;
      gap: 100px;
    }

    @include breakpoint-down('lg') {
      grid-template-columns: 500px 1fr;
      gap: 60px;
    }

    @include breakpoint-down('md') {
      grid-template-columns: 1fr;
      gap: 32px;
    }
  }

  &__image {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
