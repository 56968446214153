<script setup lang="ts">
import { useEstateListingData } from '~/composables/api/useEstateListingData'

const baseParams = {
  page: 1,
  limit: 9999,
  essential: 1,
}

const officeResponse = await useAsyncData(async () => {
  return await useEstateListingData({
    estateType: 'office',
    params: baseParams,
  })
})

const warehouseResponse = await useAsyncData(async () => {
  return await useEstateListingData({
    estateType: 'warehouse',
    params: baseParams,
  })
})

const retailResponse = await useAsyncData(async () => {
  return await useEstateListingData({
    estateType: 'retail',
    params: baseParams,
  })
})

const officeCount = computed(
  () => Math.floor((officeResponse.data.value.count || 0) / 10) * 10
)
const warehouseCount = computed(
  () => Math.floor((warehouseResponse.data.value.count || 0) / 10) * 10
)
const retailCount = computed(
  () => Math.floor((retailResponse.data.value.count || 0) / 10) * 10
)
</script>

<template>
  <div class="c-index-hero-heading">
    <h1
      class="c-index-hero-heading__title"
      v-html="
        $t('pages.index.hero.title', {
          _0: '<strong>',
          _1: '</strong>',
        })
      "
    />

    <i18n-t
      keypath="pages.index.hero.subtitle.base"
      tag="p"
      scope="global"
      class="c-index-hero-heading__subtitle"
    >
      <template #_0>
        <strong>{{
          $t('pages.index.hero.subtitle.0', { _0: officeCount }, officeCount)
        }}</strong>
      </template>

      <template #_1>
        <strong>{{
          $t(
            'pages.index.hero.subtitle.1',
            { _0: warehouseCount },
            warehouseCount
          )
        }}</strong>
      </template>

      <template #_2>
        <strong>{{
          $t('pages.index.hero.subtitle.2', { _0: retailCount }, retailCount)
        }}</strong>
      </template>
    </i18n-t>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-hero-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-light);
  gap: 32px;

  &__title {
    font-size: 72px;
    line-height: 0.94;
    margin: 0;

    :deep(strong) {
      font-weight: 400;
      color: var(--color-secondary);
      display: inline-block;
    }

    @include breakpoint-down('lg') {
      font-size: 64px;
    }
    @include breakpoint-down('md') {
      font-size: 52px;
    }
    @include breakpoint-down('sm') {
      font-size: 46px;
    }
    @include breakpoint-down('xs') {
      font-size: 36px;
    }
  }

  &__subtitle {
    max-width: 460px;
    font-size: 20px;
    line-height: 1.1;
    margin: 0;

    strong {
      display: inline-block;
    }

    @include breakpoint-down('md') {
      font-size: 18px;
    }
    @include breakpoint-down('sm') {
      font-size: 16px;
    }
    @include breakpoint-down('xs') {
      display: none;
    }
  }
}
</style>
