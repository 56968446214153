import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "48",
  height: "48",
  fill: "none",
  viewBox: "0 0 48 48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M24 43.2a13.44 13.44 0 0 0 13.44-13.44V18.24a13.44 13.44 0 0 0-26.88 0v11.52A13.44 13.44 0 0 0 24 43.2M12.48 18.24a11.52 11.52 0 1 1 23.04 0v11.52a11.52 11.52 0 0 1-23.04 0z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#17E88F",
      d: "M23.04 23.52h.086l.192.202a.96.96 0 0 0 1.363 0l.192-.202h.087v-.086l5.482-5.472-1.364-1.364-4.118 4.128V10.56h-1.92v10.166l-4.118-4.128-1.364 1.364 5.482 5.472z"
    }, null, -1)
  ])))
}
export default { render: render }