<script setup lang="ts">
import { ITabs } from '@inkline/inkline'
import Container from '../layout/Container.vue'
import EstateThumbSkeleton from '../estate/thumb/EstateThumbSkeleton.vue'
import EstateThumb from '~/components/estate/thumb/EstateThumb.vue'
import ItemsSlider from '~/components/global/ItemsSlider.vue'
import { useEstateListingData } from '~/composables/api/useEstateListingData'
import type {
  EstateListingResponse,
  EstateType,
} from '~/types/api/EstateListing'

const route = useRoute()
const router = useRouter()

const { t } = useI18n()
const tabs = [
  {
    id: 'office',
    estateType: 'office',
    title: t('pages.index.bestOffersNav.office'),
  },
  {
    id: 'warehouse',
    estateType: 'warehouse',
    title: t('pages.index.bestOffersNav.warehouse'),
  },
  {
    id: 'retail',
    estateType: 'retail',
    title: t('pages.index.bestOffersNav.retail'),
  },
] as {
  id: string
  estateType: EstateType
  title: string
}[]

const activeTabId = ref(route.query['index-best-offers-tab'] || tabs[0].id)
const activeTab = computed(() => {
  return tabs.find((tab) => tab.id === activeTabId.value) || tabs[0]
})

// TODO: needs to rewrite swiper
// FIXME: Fetch data separately for each tab to ensure it is loaded during build
const estateResponse = await useAsyncData<EstateListingResponse>(async () => {
  return await useEstateListingData({
    estateType: activeTab.value?.estateType,
    params: {
      on_index: 1,
    },
  })
})

watch(activeTabId, (newTabId) => {
  router.replace({
    query: { ...route.query, 'index-best-offers-tab': newTabId },
  })

  estateResponse.refresh()
})
</script>

<template>
  <div class="c-index-best-offers">
    <Container class="c-index-best-offers__container">
      <div class="c-index-best-offers__label">
        {{ getTextConfiguration('index.bestOffers.label') }}
      </div>

      <h2 class="c-index-best-offers__title">
        {{ getTextConfiguration('index.bestOffers.title') }}
      </h2>

      <ITabs v-model="activeTabId" class="c-index-best-offers__tabs">
        <template #header>
          <ITabTitle
            v-for="tab in tabs"
            :key="tab.id"
            :for="tab.id"
            :data-title="tab.title"
          >
            <span>{{ tab.title }}</span>
          </ITabTitle>
        </template>

        <ITab
          v-for="tab in tabs"
          :key="tab.id"
          :name="tab.id"
          class="c-index-best-offers__tab"
        >
          <ItemsSlider :slider-id="`estate-similar-${tab.id}`">
            <template v-if="estateResponse.pending.value">
              <SwiperSlide v-for="i in 3" :key="i">
                <EstateThumbSkeleton :estate-type="tab.estateType" />
              </SwiperSlide>
            </template>

            <template v-else-if="estateResponse.data?.value?.data">
              <SwiperSlide
                v-for="item in estateResponse.data?.value?.data"
                :key="item.id"
              >
                <EstateThumb
                  :estate-data="item"
                  :estate-type="item.type"
                  thumb-type="slider"
                />
              </SwiperSlide>
            </template>
          </ItemsSlider>
        </ITab>
      </ITabs>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-best-offers {
  padding-top: 70px;
  padding-bottom: 75px;
  margin-bottom: 110px;
  background-color: var(--color-solid-grey);

  @include breakpoint-down('md') {
    margin-bottom: 80px;
  }

  @include breakpoint-down('sm') {
    padding-top: 48px;
  }

  &__label {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    color: var(--color-solid-grey-dark);
    margin-bottom: 4px;
  }

  &__title {
    margin-bottom: 18px;
    text-align: center;

    @include breakpoint-down('sm') {
      font-size: 32px;
    }
  }

  &__tab {
    :deep(.c-estate-thumb-skeleton__content) {
      border: none;
      background-color: var(--color-light);
    }
    :deep(.c-estate-thumb-skeleton__gallery) {
      background-color: var(--color-solid-grey-2);
    }

    :deep(.c-estate-thumb__content) {
      border: none;
    }

    :deep(.c-items-slider__arrows) .sc-swiper-button {
      --swiper-button--background-color: var(--color-solid-grey);
    }

    @include breakpoint-down('md') {
      :deep(.c-estate-thumb) .c-estate-thumb-parameters {
        --estate-thumb-parameters--image-display: none;
        --estate-thumb-parameters--label--display: block;
      }
    }
  }
}
</style>
