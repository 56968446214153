<script setup lang="ts"></script>

<template>
  <div class="c-index-services-benefit-skeleton">
    <div class="c-index-services-benefit-skeleton__image sc-skeleton" />
    <div class="c-index-services-benefit-skeleton__name sc-skeleton" />

    <div class="c-index-services-benefit-skeleton__description">
      <span class="sc-skeleton" />
      <span class="sc-skeleton" />
      <span class="sc-skeleton" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;
@use '@/assets/styles/mixins/skeleton' as skeleton;

.c-index-services-benefit-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sc-skeleton {
    @include skeleton.skeleton;
  }

  &__image {
    width: 64px;
    height: 60px;
    margin-bottom: 28px;

    @include breakpoint-down('xs') {
      width: 48px;
      height: 45px;
      margin-bottom: 20px;
    }
  }

  &__name {
    width: 76%;
    height: 26px;
    margin-bottom: 20px;

    @include breakpoint-down('xs') {
      height: 22px;
      margin-bottom: 20px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.28;
    width: 100%;
    gap: 8px;

    .sc-skeleton {
      height: 16px;

      &:nth-child(1) {
        width: 87%;
      }
      &:nth-child(2) {
        width: 76%;
      }
      &:nth-child(3) {
        width: 64%;
      }
    }

    @include breakpoint-down('xs') {
      font-size: 15px;
    }
  }
}
</style>
